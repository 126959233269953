// EquityHeatMap.jsx
import React, { useEffect, useRef, memo } from 'react';
import { useTheme } from "../ThemeContext";

function EquityHeatMap() {
    const container = useRef();
    const { theme, toggleTheme } = useTheme();

    useEffect( () => {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-etf-heatmap.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = JSON.stringify({
                "dataSource": "AllUSEtf",
                "blockSize": "aum",
                "blockColor": "change",
                "grouping": "asset_class",
                "locale": "en",
                "symbolUrl": "",
                "colorTheme": theme,
                "hasTopBar": false,
                "isDataSetEnabled": false,
                "isZoomEnabled": true,
                "hasSymbolTooltip": true,
                "isMonoSize": false,
                "width": "100%",
                "height": 550
            });
            container.current.innerHTML = ''; 
            container.current.appendChild(script);
        },
        [theme]
    );

    return (
        <div className="tradingview-widget-container" ref={container}  style={{ height: "100%", width: "100%" }}>
            <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
        </div>
    );
}

export default EquityHeatMap;
