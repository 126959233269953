import React, { useEffect, useRef } from 'react';
import { useTheme } from "../ThemeContext";

const EconomicCalender = () => {
    const widgetContainerRef = useRef(null);
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
            "colorTheme": theme,
            "isTransparent": false,
            "width": "100%",
            "height": "100%",
            "locale": "en",
            "importanceFilter": "-1,0,1",
            "countryFilter": "ar,au,br,ca,cn,fr,de,in,id,it,jp,kr,mx,ru,sa,za,tr,gb,us,eu"
        });
        widgetContainerRef.current.innerHTML = '';
        widgetContainerRef.current.appendChild(script);

        // return () => {
        //     widgetContainerRef.current.removeChild(script);
        // };
    }, [theme]);


    return (
        <div className="tradingview-widget-container">
            <div ref={widgetContainerRef} className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default EconomicCalender;
