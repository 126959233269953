// import config
import axios, { handleResp } from '../config/axios'

import { SET_SPOT_TRADE_PAIR_LIST, SET_SPOT_MARKET_PRICE, SET_SPOT_PAIR_TRADE, SET_USER_FIRST_CURRENCY, UPDATE_SPOT_ASSET, SET_USER_SECOND_CURRENCY } from "../constant"




export const getOpenPosition = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `api/user/positionOrder`,
            params
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}


export const getAllOpenPosition = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `api/user/getALlOpenPosition`,
            params
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}



export const getOpenOrder = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `api/user/openOrderHistory`,
            params
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}

export const getTradeHistory = async (params) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `api/user/tradeHistory`,
            params
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}




export const getClosedOrder = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `api/user/closedPnL`,
            params: data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
        }
    }
}






export const getPairList = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `api/user/tradePair`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
        };
    }
};

export const setPairList = (data, dispatch) => {
    dispatch({
        type: SET_SPOT_TRADE_PAIR_LIST,
        data,
    });
};



export const setTradePair = (
    {
        firstCurrencySymbol,
        firstCurrencyId,
        firstFloatDigit,
        secondCurrencySymbol,
        secondCurrencyId,
        secondFloatDigit,
        botstatus,
        _id,
        image,
        leverage,
        tikerRoot,
        hedging,
        hedgeQty,
        hedgeStatus
    },
    dispatch
) => {
    dispatch({
        type: SET_SPOT_PAIR_TRADE,
        data: {
            firstCurrencySymbol,
            firstCurrencyId,
            firstFloatDigit,
            secondCurrencySymbol,
            secondCurrencyId,
            secondFloatDigit,
            botstatus,
            pairId: _id,
            image,
            leverage,
            tikerRoot,
            hedging,
            hedgeQty,
            hedgeStatus
        },
    });
    return true;
};



export const getMarketPrice = async (pairId, dispatch) => {
    try {
        let respData = await axios({
            method: "get",
            url: `api/user/marketPrice/${pairId}`,
        });
        // setMarketPrice(respData.data.result, dispatch);
        return true;
    } catch (err) {
        handleResp(err, "error");
        return false;
    }
};

export const setMarketPrice = (
    {
        last,
        markPrice,
        low,
        high,
        firstVolume,
        secondVolume,
        changePrice,
        change,
        botstatus,
        askPrice,
        bidPrice,
        _id,
    },
    dispatch
) => {
    dispatch({
        type: SET_SPOT_MARKET_PRICE,
        data: {
            last,
            markPrice,
            low,
            high,
            firstVolume,
            secondVolume,
            changePrice,
            change,
            botstatus,
            askPrice,
            bidPrice,
            _id,
        },
    });

    return true;
};



export const getAssetByCurrency = async (currencyId) => {
    try {
        let respData = await axios({
            method: "get",
            url: `api/user/getAsset/` + currencyId,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
        };
    }
};


export const setUserFirstCurrency = (assetData, dispatch) => {
    dispatch({
        type: SET_USER_FIRST_CURRENCY,
        data: assetData,
    });
};


export const setUserSecondCurrency = (assetData, dispatch) => {
    dispatch({
        type: SET_USER_SECOND_CURRENCY,
        data: assetData,
    });
};




export const updateTradeAsset = (dispatch, data) => {
    // console.log(data, 'datadatadtagta')
    dispatch({
        type: UPDATE_SPOT_ASSET,
        data,
    });
    return true;
};




export const orderPlace = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `api/user/orderPlace`,
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors,
            message: err.response.data.message,
        };
    }
};



export const orderClose = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `api/user/orderPlace`,
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            // error: err.response.data.errors,
            message: err.response.data.message,
        };
    }
};


export const closeAllOrder = async () => {
    try {
        let respData = await axios({
            method: "post",
            url: `api/user/closeAllOrder`,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            // error: err.response.data.errors,
            message: err.response.data.message,
        };
    }
};




export const tpslUpdate = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `api/user/tpslUpdate`,
            data,
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
            // error: err.response.data.errors,
            message: err.response.data.message,
        };
    }
};

export const getOrderBook = async (PairId) => {
    try {
        // console.log(PairId,"-----PairId")
        let respData = await axios({
            method: "get",
            url: `api/user/ordeBook/${PairId}`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
        };
    }
};


export const getHedgingList = async (params) => {
    try {
        // console.log(params,"-------------params")
        let respData = await axios({
            method: "get",
            url: `api/user/hedgingData`,
            params,
        });
        // console.log(respData,"-------------respData")

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: "failed",
            loading: false,
        };
    }
};