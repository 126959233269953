import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '../ThemeContext';

const TechnicalWidget = () => {

    const tradePair = useSelector((state) => state.tradePair);
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');

        // Set the script type and src attributes
        script.type = 'text/javascript';
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
        script.async = true;

        // Define the script's inner content as a JSON string
        script.innerHTML = JSON.stringify({
            "interval": "1m",
            "width": "100%",
            "isTransparent": false,
            "height": 500,
            "symbol": tradePair.tikerRoot,
            "showIntervalTabs": true,
            "displayMode": "single",
            "locale": "en",
            "colorTheme": theme
        });

        // Append the script to the widget container
        document.querySelector('.tradingview-widget-container__widget').appendChild(script);

        // Clean up the script on component unmount
        return () => {
            document.querySelector('.tradingview-widget-container__widget').innerHTML = '';
        };
    }, [tradePair, theme]);

    return (
        <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default TechnicalWidget;
