import React, { useContext, useEffect, useRef, useState } from "react";

import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";


// import components
import PortfolioPerformance from "../components/Hedging/PortfolioPerformance.js";
import PortfolioAllocation from "../components/Hedging/PortfolioAllocation.js";
import TradingViewWidget from "../components/Hedging/header.js"
import Footer from "../components/Footer.js";
import NavbarInner from "../components/Navbar-Inner.js";
import NewChart from "../components/Hedging/newChart.js"
import ListTable from "../components/Hedging/Table.js";

//lib
import { toFixedDown, truncateDecimals } from "../lib/roundOf";

//api
import { getPairList, setPairList } from "../api/tradeAction.js";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}


export default function Hedging(props) {



  //hooks
  const dispatch = useDispatch();


  //redux
  const pairListData = useSelector((state) => state.pairList);
  // const { userDashboard } = useSelector((state) => state.siteSetting)

  //state
  const [symbols, setSymbols] = useState([])
  const [duppairlists, setduppairlists] = useState([]);
  const [piechart, setPieChart] = useState([])
  const [available, setAvailable] = useState(0);
  const [piecurrency, setPieCurrency] = useState([])


  //function
  const fetchPairList = async () => {
    try {
      const { status, result } = await getPairList();
      if (status == "success") {
        setPairList(result, dispatch);
      }
    } catch (err) {
      console.log(err, "error");
    }

  };




  useEffect(() => {
    fetchPairList();
  }, [])

  useEffect(() => {
    if (pairListData && pairListData.length > 0) {
      let array = []
      let curArray = []
      var newarray = [];
      let symbol = []
      let totalAmount = 0;

      for (var i = 0; i < pairListData.length; i++) {
        if (pairListData[i].hedging == true && pairListData[i].hedgeStatus == "active") {
          // console.log(pairListData[i], "-pairListData[i]")
          newarray.push(pairListData[i]);
          if (pairListData[i].type == 'crypto') {
            symbol.push({ proName: "BINANCE:" + pairListData[i].firstCurrencySymbol + pairListData[i].secondCurrencySymbol })
          }
          // let color = userDashboard && userDashboard.length > 0 && userDashboard.find((el) => el.currencyId == pairListData[i].firstCurrencyId || el.currencyId == pairListData[i].secondCurrencyId)

          curArray.push({
            currency: pairListData[i].firstCurrencySymbol,
            // colorCode: color != undefined ? color.colorCode : "",
            _id: pairListData[i]._id,
            image: pairListData[i].image,
          })
          array.push(parseFloat(pairListData[i].hedgeQty) * parseFloat(pairListData[i].markPrice))
          totalAmount += parseFloat(pairListData[i].hedgeQty) * parseFloat(pairListData[i].markPrice)
        }
      }
      setSymbols(symbol)
      setAvailable(toFixedDown(totalAmount, 2))
      setPieChart(array)
      setPieCurrency(curArray)
      setduppairlists(newarray);
    }
  }, [pairListData]);





  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarInner />
      <div className="innerpages_wrapper">
        <div className="container-fluid">
          <div className="dash_wrapper">
            <div className="row">
              <div className="col-lg-12">
                <div className="dash_box">
                  <TradingViewWidget symbols={symbols} />
                </div>
                <div className="dash_box trade_top_info">
                  <h3>Total Hedge Fund Portfolio Value: </h3>
                  <h3 style={{ color: available >= 0 ? "#00cdf9" : "#dc3545" }}>
                    ${" "}
                    <CurrencyFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={available}
                    />
                  </h3>
                </div>
                <div className="row">
                  <div className="col-lg-7 d-flex">
                    <div className="dash_box w-100">
                      <div class="orderbook_header">
                        <h6 class="spot_head">Portfolio Performance</h6>
                      </div>
                      <NewChart piecurrency={piecurrency} />
                      {/* <PortfolioPerformance /> */}
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex">
                    <div className="dash_box w-100">
                      <div class="orderbook_header">
                        <h6 class="spot_head">Portfolio Allocation</h6>
                      </div>
                      <PortfolioAllocation piechart={piechart} piecurrency={piecurrency} />
                    </div>
                  </div>
                </div>
                <div className="dash_box">
                  <div className="primary_datatable">
                    <ListTable duppairlists={duppairlists} setduppairlists={setduppairlists} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
