import React, { useEffect, useRef } from 'react';
import { useTheme } from "../ThemeContext";

const TopStories = () => {
    const widgetContainerRef = useRef(null);
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
            "feedMode": "all_symbols",
            "isTransparent": false,
            "displayMode": "regular",
            "width": "100%",
            "height": "100%",
            "colorTheme": theme,
            "locale": "en"
        });
        widgetContainerRef.current.innerHTML = '';
        widgetContainerRef.current.appendChild(script);

        // return () => {
        //     widgetContainerRef.current.removeChild(script);
        // };
    }, [theme]);


    return (
        <div className="tradingview-widget-container">
            <div ref={widgetContainerRef} className="tradingview-widget-container__widget"></div>
        </div>
    );
};



export default TopStories;