import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import browser from "browser-detect";
import Bowser from "bowser";

// import API calls
import { emailVerify, resetPasswordVerification, loginWithurl, getGeoInfoData } from '../api/users';

// import helper functions
import { toastAlert } from '../lib/toastAlert';
import { useDispatch } from "react-redux";

const EmailVerification = () => {
	const { authToken } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	// Retrieve and format login history
	const getGeoInfo = async () => {
		try {
			const { result } = await getGeoInfoData();
			const browserResult = browser();
			const Browser = Bowser.getParser(window.navigator.userAgent);

			return {
				countryName: result?.country_name || "",
				countryCode: result?.country_calling_code || "",
				ipAddress: result?.ip || "",
				regionName: result?.region || "",
				broswerName: `${Browser.getBrowserName()} ${Browser.getBrowserVersion()}`,
				isMobile: browserResult.mobile,
				os: Browser.getOSName(),
			};
		} catch (err) {
			console.error("Error retrieving geo info:", err);
			return {};
		}
	};

	// Handle user login with token
	const getloginToken = async (isUserLogin) => {
		try {
			const loginHistory = await getGeoInfo();

			console.log("loginHistoryloginHistoryloginHistory", loginHistory)
			const { status, message } = await loginWithurl({ 
				userId: authToken, 
				data: isUserLogin, 
				loginHistory 
			}, dispatch);
			
			toastAlert(status === 'success' ? 'success' : 'error', message, 'loginWithurl');
			navigate('/wallet');
		} catch (err) {
			console.error("Error during login:", err);
		}
	};

	// Handle password reset
	const ResetPassword = async () => {
		try {
			const { status, message } = await resetPasswordVerification({ authToken });
			toastAlert(status === 'success' ? 'success' : 'error', message, 'resetPassword');
			navigate(status === 'success' ? `/reset-password/${authToken}` : '/login');
		} catch (error) {
			console.error("Error during password reset:", error);
		}
	};

	// Check the URL path and call the corresponding function
	useEffect(() => {
		const pathname = location.pathname;

		if (pathname.includes('/verification/forgotPassword/')) {
			ResetPassword();
		} else if (pathname.includes('/url-login/')) {
			getloginToken(false);
		} else if (pathname.includes('/user-login/')) {
			getloginToken(true);
		}
	}, [authToken, location.pathname]);

	return (
		<h3>Loading...</h3>
	);
}

export default EmailVerification;
