import React, { useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from "../ThemeContext";

function AreaChart() {
    const container = useRef();
    const tradePair = useSelector((state) => state.tradePair);
    const { theme, toggleTheme } = useTheme();

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = JSON.stringify({
            autosize: true,
            symbol: tradePair.tikerRoot,
            interval: "D",
            timezone: "Etc/UTC",
            theme: theme,
            style: "3",
            hide_top_toolbar: true,
            save_image: false,
            support_host: "https://www.tradingview.com"
        });
        container.current.innerHTML = ''; // Clear previous widget if any
        container.current.appendChild(script);
    }, [tradePair, theme]);

    return (
        <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
            <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
        </div>
    );
}

export default AreaChart;
